import { Edit, TabbedForm } from 'react-admin';
import SummaryTab from './Forms/SummaryTab';
import EditToolbar from '../../Components/EditToolbar';
import { AffiliateNetworkResource } from '../../Entities/AffiliateNetwork';

export default function AffiliateEdit(): JSX.Element {
  return (
    <Edit<AffiliateNetworkResource> mutationMode="pessimistic">
      <TabbedForm toolbar={<EditToolbar />}>
        <SummaryTab />
      </TabbedForm>
    </Edit>
  );
}

import { Resource } from '@react-admin/ra-rbac';
import { BrowserRouter, Route } from 'react-router-dom';
import { Admin, defaultTheme, defaultDarkTheme, CustomRoutes } from 'react-admin';
import {
  Category as CategoryIcon,
  Factory,
  Person,
  ShoppingCart,
  TravelExplore,
  PhoneIphone,
  LocalOffer,
  Lan,
} from '@mui/icons-material';
import Dashboard from './Components/Dashboard';
import { AuthProvider, DataProvider } from './DataProvider';
import * as Category from './Resources/Category';
import * as Product from './Resources/Product';
import * as Merchant from './Resources/Merchant';
import * as Offer from './Resources/Offer';
import * as Region from './Resources/Region';
import * as Registration from './Resources/Registration';
import * as Manufacturer from './Resources/Manufacturer';
import * as AffiliateNetwork from './Resources/AffiliateNetwork';
import Layout from './Components/Layout';
import ExpiringCache from './ExpiringCache';
import Preferences from './CustomRoutes/Preferences';

export default function App(): JSX.Element {
  const cacheTime = Number(import.meta.env.RESOURCE_CACHE_TIMEOUT_MS ?? 500);
  const resourceCache = new ExpiringCache('dataprovider.cache', cacheTime);
  const apiUrl = import.meta.env.VITE_API_URL;

  return (
    <BrowserRouter>
      <Admin
        authProvider={AuthProvider}
        dataProvider={DataProvider(apiUrl, resourceCache)}
        theme={defaultTheme}
        darkTheme={defaultDarkTheme}
        dashboard={Dashboard}
        disableTelemetry
        layout={Layout}
      >
        <Resource
          name="categories"
          icon={CategoryIcon}
          create={Category.Create}
          edit={Category.Edit}
          list={Category.List}
        />
        <Resource
          name="products"
          icon={PhoneIphone}
          create={Product.Create}
          edit={Product.Edit}
          list={Product.List}
        />
        <Resource name="offers" icon={LocalOffer} edit={Offer.Edit} list={Offer.List} />
        <Resource
          name="merchants"
          icon={ShoppingCart}
          create={Merchant.Create}
          edit={Merchant.Edit}
          list={Merchant.List}
        />
        <Resource
          name="registrations"
          icon={Person}
          create={Registration.Create}
          edit={Registration.Edit}
          list={Registration.List}
        />
        <Resource
          name="manufacturers"
          icon={Factory}
          create={Manufacturer.Create}
          edit={Manufacturer.Edit}
          list={Manufacturer.List}
        />
        <Resource
          name="regions"
          icon={TravelExplore}
          create={Region.Create}
          edit={Region.Edit}
          list={Region.List}
        />
        <Resource
          name="affiliate_networks"
          icon={Lan}
          create={AffiliateNetwork.Create}
          edit={AffiliateNetwork.Edit}
          list={AffiliateNetwork.List}
          options={{ label: 'Affiliate Networks' }}
        />
        <CustomRoutes>
          <Route path="/preferences" element={<Preferences />} />
        </CustomRoutes>
      </Admin>
    </BrowserRouter>
  );
}

import {
  DatagridConfigurable,
  List,
  NumberInput,
  ReferenceOneField,
  TextField,
  TextInput,
  usePermissions,
} from 'react-admin';
import { canAccess } from '@react-admin/ra-rbac';
import { DEFAULT_LIST_PAGE_PAGINATION_LIMIT } from '../../utils';
import { AffiliateNetworkResource } from '../../Entities/AffiliateNetwork';

const filters = [
  <NumberInput key="filter.id" label="ID" source="equals.id" />,
  <TextInput key="filter.name" label="Name" source="contains.name" />,
  <TextInput key="filter.subtag_name" label="Subtag" source="contains.subtag_name" />,
];

export default function AffiliateList(): JSX.Element {
  const { permissions } = usePermissions();
  return (
    <List<AffiliateNetworkResource>
      perPage={DEFAULT_LIST_PAGE_PAGINATION_LIMIT}
      sort={{ order: 'DESC', field: 'id' }}
      filters={filters}
    >
      <DatagridConfigurable>
        <TextField label="ID" source="id" />
        <TextField label="Name" source="attributes.name" />
        <TextField label="Subtag" source="attributes.subtag_name" />
        {canAccess({ permissions, action: 'list', resource: 'merchants' }) && (
          <ReferenceOneField
            label="Merchant"
            link="edit"
            target="affiliate_network.id"
            reference="merchants"
          >
            <TextField source="attributes.merchant_name" />
          </ReferenceOneField>
        )}
      </DatagridConfigurable>
    </List>
  );
}

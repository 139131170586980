import { Create, TabbedForm } from 'react-admin';
import { AffiliateNetworkResource } from '../../Entities/AffiliateNetwork';
import SummaryTab from './Forms/SummaryTab';

export default function AffiliateCreate(): JSX.Element {
  return (
    <Create<AffiliateNetworkResource> redirect="edit">
      <TabbedForm>
        <SummaryTab />
      </TabbedForm>
    </Create>
  );
}

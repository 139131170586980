import { InspectorButton, AppBar as RAAppBar, TitlePortal } from 'react-admin';
import { Box } from '@mui/material';
import GetpriceLogo from '../getprice-logo.svg';
import UserMenu from './UserMenu';

export default function AppBar(): JSX.Element {
  return (
    <RAAppBar userMenu={<UserMenu />}>
      <TitlePortal flex="0 0 auto" />
      <Box flex="1" />
      <img src={GetpriceLogo} alt="Getprice Logo" />
      <Box flex="1" />
      <InspectorButton />
    </RAAppBar>
  );
}

import { canAccess } from '@react-admin/ra-rbac';
import {
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Edit,
  FormDataConsumer,
  FunctionField,
  ImageField,
  Labeled,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectField,
  TextField,
  TextInput,
  UrlField,
  usePermissions,
} from 'react-admin';
import { Box, Divider, Grid } from '@mui/material';
import { AccordionForm } from '@react-admin/ra-form-layout';
import TzDateField from '../../Components/TzDate/TzDateField';
import { IMAGE_STATUS_CHOICES } from './utils';
import SadPanda from '../../Components/SadPanda';
import EditToolbar from '../../Components/EditToolbar';
import { OfferResource } from '../../Entities/Offer';

export default function OfferEdit(): JSX.Element {
  const { permissions } = usePermissions();

  return (
    <Edit component="div">
      <AccordionForm toolbar={<EditToolbar disableDelete />}>
        <AccordionForm.Panel label="Offer Details" defaultExpanded>
          <Grid container spacing={2}>
            <Grid item xs={12} xl={6}>
              <Box flex="1">
                <Labeled>
                  <TextField label="Name" source="attributes.name" />
                </Labeled>
              </Box>
              {canAccess({ permissions, action: 'list', resource: 'merchants' }) && (
                <Box flex="1">
                  <Labeled>
                    <ReferenceField
                      label="Merchant"
                      source="relationships.merchant.data.id"
                      reference="merchants"
                    >
                      <TextField label="Merchant" source="attributes.merchant_name" />
                    </ReferenceField>
                  </Labeled>
                </Box>
              )}
              <Box flex="1">
                <Labeled>
                  <UrlField
                    label="URL"
                    source="attributes.url"
                    style={{ wordBreak: 'break-all' }}
                  />
                </Labeled>
              </Box>
              <Box flex="1">
                <Labeled>
                  <TextField label="Description" source="attributes.description" />
                </Labeled>
              </Box>
            </Grid>
            <Grid item xs={6} xl={3}>
              <Box flex="1">
                <Labeled>
                  <FormDataConsumer label="Current Price">
                    {({ formData }) => (
                      <NumberField
                        source="attributes.current_price"
                        options={{
                          style: 'currency',
                          currency: formData?.attributes?.currency ?? 'AUD',
                        }}
                      />
                    )}
                  </FormDataConsumer>
                </Labeled>
              </Box>
              <Box flex="1">
                <Labeled>
                  <FormDataConsumer label="Normal Price">
                    {({ formData }) => (
                      <NumberField
                        source="attributes.normal_price"
                        emptyText="N/A"
                        options={{
                          style: 'currency',
                          currency: formData?.attributes?.currency ?? 'AUD',
                        }}
                      />
                    )}
                  </FormDataConsumer>
                </Labeled>
              </Box>
              <Divider flexItem />
              <Box flex="1">
                <Labeled>
                  <TzDateField
                    label="Created At"
                    source="attributes.created_at"
                    emptyText="Unknown"
                    showTime
                  />
                </Labeled>
              </Box>
              <Box flex="1">
                <Labeled>
                  <TzDateField label="Last Edited" source="attributes.edited_at" showTime />
                </Labeled>
              </Box>
              {canAccess({ permissions, action: 'list', resource: 'merchants' }) && (
                <Box flex="1">
                  <Labeled>
                    <ReferenceField
                      label="Last Scanned At"
                      source="relationships.last_scan.data.id"
                      reference="scans"
                    >
                      <TzDateField source="attributes.end_date" showTime />
                    </ReferenceField>
                  </Labeled>
                </Box>
              )}
            </Grid>
            <Grid item xs={6} xl={3}>
              <Box flex="1">
                <Labeled>
                  <TextField label="Currency" source="attributes.currency" />
                </Labeled>
              </Box>
              <Box flex="1">
                <Labeled>
                  <FormDataConsumer label="Shipping Cost">
                    {({ formData }) => (
                      <NumberField
                        source="attributes.shipping_cost"
                        emptyText="Unknown / N/A"
                        options={{
                          style: 'currency',
                          currency: formData?.attributes?.currency ?? 'AUD',
                        }}
                      />
                    )}
                  </FormDataConsumer>
                </Labeled>
              </Box>
              <Divider flexItem />
              <Box flex="1">
                <Labeled>
                  <TzDateField
                    label="Offer Start Time"
                    source="attributes.starts_at"
                    emptyText="N/A"
                  />
                </Labeled>
              </Box>
              <Box flex="1">
                <Labeled>
                  <TzDateField label="Offer End Time" source="attributes.ends_at" emptyText="N/A" />
                </Labeled>
              </Box>
              <Box flex="1">
                <Labeled>
                  <BooleanField label="In Stock?" source="attributes.is_in_stock" looseValue />
                </Labeled>
              </Box>
            </Grid>
          </Grid>
        </AccordionForm.Panel>
        <AccordionForm.Panel label="Offer Detail Overrides">
          <TextInput label="Manufacturer" source="attributes.manufacturer" />
          <TextInput label="Model" source="attributes.model" />
          <TextInput label="GTIN" source="attributes.gtin" />
          <TextInput label="MPN" source="attributes.mpn" />
        </AccordionForm.Panel>
        <AccordionForm.Panel label="Getprice Details" defaultExpanded>
          <Grid container spacing={2}>
            {(canAccess({ permissions, action: 'list', resource: 'products' }) ||
              canAccess({ permissions, action: 'list', resource: 'categories' })) && (
              <Grid item xs={12} xl={9}>
                {canAccess({ permissions, action: 'list', resource: 'products' }) && (
                  <ReferenceInput source="relationships.product.data.id" reference="products">
                    <AutocompleteInput
                      label="Getprice Product"
                      optionText="attributes.name"
                      filterToQuery={(query: string) =>
                        query.match(/^\d+$/)
                          ? { or: { id: query, contains: { name: query } } }
                          : { contains: { name: query } }
                      }
                    />
                  </ReferenceInput>
                )}
                {canAccess({ permissions, action: 'list', resource: 'categories' }) && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} xl={10}>
                      <Box flex="1">
                        <ReferenceInput
                          source="relationships.category.data.id"
                          reference="categories"
                        >
                          <AutocompleteInput
                            fullWidth
                            label="Category"
                            optionText="attributes.full_name"
                            filterToQuery={(query: string) => ({ contains: { name: query } })}
                          />
                        </ReferenceInput>
                      </Box>
                    </Grid>
                    <Grid item xs={12} xl={2}>
                      <Labeled>
                        <BooleanField
                          label="Auto Categorized?"
                          source="attributes.is_auto_categorized"
                          looseValue
                        />
                      </Labeled>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
            <Grid item xs={12} xl={3}>
              <Box flex="1">
                <BooleanInput label="Approved?" source="attributes.is_approved" />{' '}
              </Box>
              <Box flex="1">
                <BooleanInput label="For Adult Audiences?" source="attributes.is_adult" />
              </Box>
            </Grid>
          </Grid>
        </AccordionForm.Panel>
        <AccordionForm.Panel label="Image & Other Details" defaultExpanded>
          <Grid container spacing={2}>
            <Grid item xs={12} xl={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} xl={6}>
                  <Labeled label="Downloaded Image">
                    <ImageField source="attributes.image_url" />
                  </Labeled>
                </Grid>
                <Grid item xs={12} xl={6}>
                  <Box flex="1">
                    <Labeled>
                      <SelectField
                        label="Download Status"
                        source="attributes.image_status"
                        choices={IMAGE_STATUS_CHOICES}
                      />
                    </Labeled>
                  </Box>
                  <Box flex="1">
                    <Labeled>
                      <TzDateField
                        label="Last Download Attempted At"
                        source="attributes.external_image_last_downloaded_at"
                        showTime
                      />
                    </Labeled>
                  </Box>
                  <Box flex="1">
                    <Labeled>
                      <NumberField
                        label="Last Download Attempts"
                        source="attributes.external_image_last_download_attempts"
                      />
                    </Labeled>
                  </Box>
                  <Box flex="1">
                    <Labeled label="Upload New Image">
                      <SadPanda ticket="https://purch1.atlassian.net/browse/GET-672" />
                    </Labeled>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} xl={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} xl={6}>
                  <Labeled>
                    <FunctionField
                      label="Image / From Feed"
                      render={(record?: Partial<OfferResource>) => {
                        const imageUrl = record?.attributes?.external_image_url;
                        if (!imageUrl) {
                          return null;
                        }

                        return (
                          <img
                            style={{ maxWidth: '100%' }}
                            src={imageUrl}
                            alt="Direct from merchant"
                          />
                        );
                      }}
                    />
                  </Labeled>
                </Grid>
                <Grid item xs={12} xl={6}>
                  <Box flex="1">
                    <Labeled>
                      <TextField
                        label="Manufacturer / From Feed"
                        source="attributes.external_manufacturer"
                      />
                    </Labeled>
                  </Box>
                  <Box flex="1">
                    <Labeled>
                      <TextField
                        label="Category / From Feed"
                        source="attributes.external_category"
                      />
                    </Labeled>
                  </Box>{' '}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionForm.Panel>
      </AccordionForm>
    </Edit>
  );
}
